import React, { useState } from "react";
import axios from "axios";

type FormData = {
  role: string;
  locations: string[];
  frequency: string;
  duration: string;
  harassmentTypes: string[];
  victimExample: string;
  questions: string;
  firstname: string;
  email: string;
  confirmEmail: string;
};

const Signalements = () => {
  const [formData, setFormData] = useState<FormData>({
    role: "",
    locations: [],
    frequency: "",
    duration: "",
    harassmentTypes: [],
    victimExample: "",
    questions: "",
    firstname: "",
    email: "",
    confirmEmail: "",
  });

  const [status, setStatus] = useState<string | null>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      const checked = (e.target as HTMLInputElement).checked;
      setFormData((prevState) => {
        const array = prevState[name as keyof FormData] as string[];
        return {
          ...prevState,
          [name]: checked
            ? [...array, value]
            : array.filter((item) => item !== value),
        };
      });
    } else if (type === "radio") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Check required fields
    if (
      !formData.frequency ||
      !formData.duration ||
      formData.harassmentTypes.length === 0
    ) {
      setStatus("error");
      alert("Veuillez remplir tous les champs obligatoires.");
      return;
    }

    try {
      console.log("Submitting formData:", formData); // Debugging log

      const response = await axios.post(
        "https://api-scrutin.oremis.fr/report-harassment",
        formData
      );

      if (response.status === 200) {
        setStatus("success");
      } else {
        setStatus("error");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setStatus("error");
    }
  };

  return (
    <div className="container my-5" style={{ fontFamily: "Arial, sans-serif" }}>
      <h1 className="text-center mb-4" style={{ color: "#0d47a1" }}>
        PLATEFORME DE SIGNALEMENT HARCÈLEMENT SCOLAIRE
      </h1>
      <p>
        Bienvenue sur la plateforme de signalement de l'association Orémis. Si
        vous êtes élève, parent, enseignant ou membre du personnel scolaire et
        que vous êtes témoin ou concerné par une situation de harcèlement
        scolaire, sachez que nous sommes là pour vous accompagner. Merci de
        remplir ce formulaire avec le plus de précision possible. Vos réponses
        resteront strictement confidentielles et nous reviendrons vers vous
        rapidement avec les bénévoles les mieux adaptés pour vous soutenir et
        répondre à vos besoins.
      </p>

      <form onSubmit={handleSubmit}>
        <div className="row">
          {/* Left column */}
          <div className="col-md-6">
            <fieldset className="mb-4">
              <legend className="h5">Vous êtes ? *</legend>
              {["Victime", "Témoin", "Parents d'une victime", "Personnel d'un établissement"].map(
                (role, index) => (
                  <div className="form-check" key={index}>
                    <input
                      type="radio"
                      id={`role${index}`}
                      name="role"
                      value={role}
                      onChange={handleChange}
                      className="form-check-input"
                    />
                    <label className="form-check-label" htmlFor={`role${index}`}>
                      {role}
                    </label>
                  </div>
                )
              )}
            </fieldset>

            <fieldset className="mb-4">
              <legend className="h5">Pouvez-vous décrire la situation ?</legend>
              <p>Lieu du harcèlement :</p>
              {["Sur le net", "A l'école", "Au collège", "Au lycée", "Club de sport"].map(
                (location, index) => (
                  <div className="form-check" key={index}>
                    <input
                      type="checkbox"
                      id={`location${index}`}
                      name="locations"
                      value={location}
                      onChange={handleChange}
                      className="form-check-input"
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`location${index}`}
                    >
                      {location}
                    </label>
                  </div>
                )
              )}
            </fieldset>

            <fieldset className="mb-4">
              <legend className="h5">Fréquence du harcèlement *</legend>
              {["moins d'1 fois par semaine", "Plusieurs fois par semaine", "Tous les jours"].map(
                (freq, index) => (
                  <div className="form-check" key={index}>
                    <input
                      type="radio"
                      id={`frequency${index}`}
                      name="frequency"
                      value={freq}
                      onChange={handleChange}
                      className="form-check-input"
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`frequency${index}`}
                    >
                      {freq}
                    </label>
                  </div>
                )
              )}
            </fieldset>

            <fieldset className="mb-4">
              <legend className="h5">Durée du harcèlement *</legend>
              {["- de 6 mois", "+ de 6 mois", "+ d'un an"].map((duration, index) => (
                <div className="form-check" key={index}>
                  <input
                    type="radio"
                    id={`duration${index}`}
                    name="duration"
                    value={duration}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`duration${index}`}
                  >
                    {duration}
                  </label>
                </div>
              ))}
            </fieldset>

            <fieldset className="mb-4">
              <legend className="h5">Type de harcèlement *</legend>
              {["Verbal", "Physique", "Sexuel"].map((type, index) => (
                <div className="form-check" key={index}>
                  <input
                    type="checkbox"
                    id={`harassmentTypes${index}`}
                    name="harassmentTypes"
                    value={type}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`harassmentTypes${index}`}
                  >
                    {type}
                  </label>
                </div>
              ))}
            </fieldset>
          </div>

          {/* Right column */}
          <div className="col-md-6">
            <fieldset className="mb-4">
              <legend className="h5">
                Description d'un exemple vécu par la victime (2500 caractères
                max)
              </legend>
              <textarea
                name="victimExample"
                onChange={handleChange}
                className="form-control"
                rows={5}
                maxLength={2500}
              ></textarea>
            </fieldset>

            <fieldset className="mb-4">
              <legend className="h5">
                Avez-vous des questions pour nos bénévoles ? (2500 caractères
                max)
              </legend>
              <textarea
                name="questions"
                onChange={handleChange}
                className="form-control"
                rows={5}
                maxLength={2500}
              ></textarea>
            </fieldset>

            <fieldset className="mb-4">
              <legend className="h5">A propos de vous :</legend>
              <div className="mb-3">
                <label htmlFor="firstname" className="form-label">
                  Prénom *
                </label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  E-mail *
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="confirmEmail" className="form-label">
                  Confirmation e-mail *
                </label>
                <input
                  type="email"
                  id="confirmEmail"
                  name="confirmEmail"
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
            </fieldset>
          </div>
        </div>

        <div className="d-flex justify-content-between mt-4">
          <button type="button" className="btn btn-secondary">
            Retour
          </button>
          <button type="submit" className="btn btn-primary">
            Envoyer
          </button>
        </div>

        {status === "success" && (
          <p className="text-success mt-3">Signalement envoyé avec succès !</p>
        )}
        {status === "error" && (
          <p className="text-danger mt-3">Erreur lors de l'envoi du signalement.</p>
        )}
      </form>
    </div>
  );
};

export default Signalements;
