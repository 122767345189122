import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";

import Signalements from './pages/Signalements';


const router = createBrowserRouter([{
  path: '/',
  element: <Signalements/>
},{
  path:'/signalements',
  element: <Signalements/>
}])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);